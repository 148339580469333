<template>
  <v-container>
    <div>
      <v-row justify="space-between" no-gutters>
        <h1 class="primary--text">Utilisateurs</h1>
        <v-btn @click="createUser" color="primary">Créer un utilisateur</v-btn>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Nom
            </th>
            <th class="text-left">
              Groupe
            </th>
            <th class="text-left">
              E-mail
            </th>
            <th class="text-left">
              Actions
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="user in users"
              :key="user.id"
          >
            <td>{{ user.first_name + " " + user.last_name }}</td>
            <td>{{ user.group }}</td>
            <td>{{ user.email }}</td>
            <td>
              <v-btn color="primary" v-if="logged_user.group === 'Administrateur' || user.id === logged_user.id" @click="editUser(user)">
                <v-icon>mdi-pencil</v-icon>
                Modifier
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-row class="mt-4" no-gutters>
        <v-col cols="12"><h2 class="primary--text">Autres actions</h2></v-col>
        <v-col cols="12">
          <v-btn @click="generateDefaultGroups" v-if="logged_user.group === 'Administrateur'" color="primary" class="white--text">
            <v-icon>mdi-plus</v-icon>
            Générer les groupes par défaut
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog min-height="400" max-width="600" v-model="userDialog">
      <UserForm :key="selected_user_id" @reloadUsers="reloadUsers" :user="selected_user" :operation="operation"/>
    </v-dialog>
  </v-container>
</template>

<script>
import UserForm from "@/views/UserForm";

export default {
  name: "Users",
  components: {UserForm},
  async created() {
    this.loading = false;
    this.users = await this.$store.dispatch("users/fetchUsers");
    this.loading = true;
  },
  data() {
    return {
      loading: false,
      users: [],
      userDialog: false,
      selected_user: null,
      operation: "create",
      selected_user_id: 0,
    }
  },
  methods: {
    editUser(user) {
      this.selected_user_id = user.id;
      this.selected_user = user;
      this.operation = "update";
      this.userDialog = true;
    },
    createUser() {
      this.selected_user_id = 0;
      this.selected_user = null;
      this.operation = "create";
      this.userDialog = true;
    },
    async reloadUsers() {
      this.userDialog = false;
      this.loading = false;
      this.users = await this.$store.dispatch("users/fetchUsers");
      this.loading = true;
    },
    async generateDefaultGroups() {
      let result = await this.$store.dispatch("groups/generateDefaultGroups");
      if (result === "success") {
        this.$store.dispatch("annonce/annonceSuccess", "Groupes par défaut générés.");
      }
    }
  },
  computed: {
    logged_user() {
      return this.$store.getters["auth/getLoggedUser"];
    }
  }
}
</script>

